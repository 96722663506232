<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        class="w-100 pt-6"
        ref="settingForm"
        v-model="isFormValid"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              :value="setting.displayName"
              disabled
              readonly
              outlined
              label="Nazwa"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-if="valueIsBoolean"
              v-model="value"
              outlined
              :items="[
                { name: 'Tak', value: true },
                { name: 'Nie', value: false }
              ]"
              item-text="name"
              label="Wartość"
              :disabled="isProcessing"
              :rules="[rules.required]"
              append-icon="mdi-chevron-down"
              placeholder="Wybierz wartość"
              dense
            />
            <v-text-field
              v-else
              v-model="value"
              outlined
              label="Wartość"
              placeholder="Wpisz wartość"
              :disabled="isProcessing"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="isProcessing"
        @click="submitSettingEdit"
      >
        Zaktualizuj ustawienie
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import rules from '../../utils/validators'
import api from '../../api/v1'

export default {
  data() {
    return {
      value: null,
      rules,
      isFormValid: false
    }
  },
  mounted() {
    this.value = this.setting.value
  },
  components: {
    DialogFormWrapper
  },
  computed: {
    ...mapState({
      setting: state => state.layout.dialog.item,
      isProcessing: state => state.settings.isProcessing
    }),
    valueIsBoolean() {
      return typeof this.setting.value === 'boolean'
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      getItems: 'settings/getItems',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    submitSettingEdit() {
      this.$refs.settingForm.validate()
      if (!this.isFormValid) return

      const params = {
        [this.setting.var]: this.value,
      }
      api.updateSetting(params).then(() => {
        this.getItems()
        this.closeDialog()
        this.showSnackbar({ message: ['Zaktualizowano ustawienie'] })
      })
    }
  }
}
</script>
