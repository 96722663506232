import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{scopedSlots:_vm._u([{key:"form",fn:function(){return [_c(VForm,{ref:"settingForm",staticClass:"w-100 pt-6",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"value":_vm.setting.displayName,"disabled":"","readonly":"","outlined":"","label":"Nazwa"}})],1),_c(VCol,{attrs:{"cols":"12"}},[(_vm.valueIsBoolean)?_c(VAutocomplete,{attrs:{"outlined":"","items":[
              { name: 'Tak', value: true },
              { name: 'Nie', value: false }
            ],"item-text":"name","label":"Wartość","disabled":_vm.isProcessing,"rules":[_vm.rules.required],"append-icon":"mdi-chevron-down","placeholder":"Wybierz wartość","dense":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_c(VTextField,{attrs:{"outlined":"","label":"Wartość","placeholder":"Wpisz wartość","disabled":_vm.isProcessing},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)],1)]},proxy:true},{key:"submit",fn:function(){return [_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","loading":_vm.isProcessing,"disabled":_vm.isProcessing},on:{"click":_vm.submitSettingEdit}},[_vm._v(" Zaktualizuj ustawienie ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }